import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Box, Container, Typography } from "@mui/material";
import Img1 from "../../images/01.jpg";
import Img2 from "../../images/02.jpg";
import Img3 from "../../images/03.jpg";
import Img4 from "../../images/04.jpg";
import Img5 from "../../images/05.jpg";
import Img6 from "../../images/06.jpg";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function ImageGallery() {
  return (
    <Box component="section" className="image-gallery">
      <Container maxWidth="xl">
        <Typography
          component="h2"
          variant="h1"
          className="section-title text-primary"
        >
          Temple Deities Photos
        </Typography>

        <ImageList variant="quilted" cols={3} rowHeight={320} gap={30}>
          {itemData.map((item) => (
            <ImageListItem
              key={item.img}
              cols={item.cols || 1}
              rows={item.rows || 1}
            >
              <img
                {...srcset(item.img, 320, item.rows, item.cols)}
                alt={item.title}
                loading="lazy"
                className="theme-radius"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Container>
    </Box>
  );
}

const itemData = [
  {
    img: Img1,
    title: "Img1",
  },
  {
    img: Img2,
    title: "Img2",
  },
  {
    img: Img3,
    title: "Img3",
  },
  {
    img: Img4,
    title: "Img4",
    rows: 2,
    cols: 2,
  },
  {
    img: Img5,
    title: "Img5",
  },
  {
    img: Img6,
    title: "Img6",
  },
];
