import React from "react";
import Header from "./header/Header";
import { useTheme } from "../contexts/ThemeContext.js";
import Footer from "./footer/Footer.js";

function PageLayout({ children }) {
  const { theme, toggleTheme } = useTheme();

  return (
    <>
      <Header theme={theme} toggleTheme={toggleTheme} />
      {children}
    <Footer/>
    </>
  );
}

export default PageLayout;
