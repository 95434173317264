import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Box, Container, Typography, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import BannerImage from "../../images/banner-bg.jpg";
import { heroData } from "./hero-data"; // Import the heroData
import "./hero.css";
function HeroSlider() {
  const settings = {
    dots: heroData.length > 1,
    arrows: false,
    infinite: heroData.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Box
      component="section"
      className="banner"
      sx={{
        backgroundImage: `url(${BannerImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container maxWidth="xl">
        <Slider {...settings}>
          {heroData.map((item, index) => (
            <div key={index}>
              <Box sx={{ flexGrow: 1, padding: 2 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Grid item size={{ md: 6 }}>
                    <Box
                      component="img"
                      src={item.image}
                      className="w-100 theme-radius"
                    />
                  </Grid>
                  <Grid item size={{ md: 5 }}>
                    <Box className="text-white">
                      <Typography variant="h1" gutterBottom>
                        {item.title}
                      </Typography>
                      <Typography className="lead" gutterBottom>
                        {item.tagline}
                      </Typography>
                      <Button
                        component="a"
                        href={item.buttonUrl}
                        variant="contained"
                        color="white"
                        className="text-primary"
                        sx={{ mt: 3 }}
                      >
                        {item.buttonText}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </div>
          ))}
        </Slider>
      </Container>
    </Box>
  );
}
export default HeroSlider;
