import React from "react";
import { Box, Card, Container, Link, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

// Import images
import OmImg from "../../images/om.svg";
import PledgeImg from "../../images/pledge.svg"; // Example image, replace with the actual one

// Data for the blocks
const actionBlocks = [
  {
    id: 1,
    imgSrc: OmImg,
    title: "Puja & Prayers Request Scheduling",
    link: "https://hindutemplenebraska.org/puja-type.php?",
  },
  {
    id: 2,
    imgSrc: PledgeImg,
    title: "Pledge Your Support",
    link: "https://hindutemplenebraska.org/donate.php",
  },
];

function CallForAction() {
  return (
    <Box component="section">
      <Container maxWidth="xl">
        <Card className="bg-primary text-white callforaction" sx={{ py: 5, px: 2 }}>
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {actionBlocks.map((block) => (
              <Grid item size={{ md: 6 }} key={block.id} sx={{px:3}}>
                <Link href={block.link} target="_blank" style={{ textDecoration: "none" }}>
                  <Box
                    className="call-for-action-section"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Box
                      className="call-for-action-block"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={block.imgSrc}
                        className="icon-large"
                        alt={`${block.title} Icon`}
                      />
                      <Typography
                        component="h3"
                        variant="h2"
                        color="text.primary"
                        className="text-white"
                      >
                        {block.title}
                      </Typography>
                    </Box>
                    <Box component="span" sx={{ marginLeft: 2 }}>
                      <ArrowRightAltIcon />
                    </Box>
                  </Box>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Card>
      </Container>
    </Box>
  );
}

export default CallForAction;
