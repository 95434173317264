import React from "react";
import PageLayout from "../layouts/PageLayout.js";
import HeroSlider from "../components/hero-slider/HeroSlider.js";
import CallForActtion from "../components/call-for-action/CallForActtion.js";
import ImageGallery from "../components/image-gallery/ImageGallery.js";
import JoinOurCommunication from "../components/join-our-communication /JoinOurCommunication.js";
import UpcomingEvents from "../components/upcoming-events/UpcomingEvents.js";

function Home() {
  return <PageLayout>
<HeroSlider/>
<CallForActtion/>
<UpcomingEvents/>
<JoinOurCommunication/>
<ImageGallery/>
  </PageLayout>;
}

export default Home;
