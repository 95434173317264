import React from "react";
import { Box, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import { navItems } from "./navItems";

function MobileNav({ onClose }) {
  return (
    <Box onClick={onClose} sx={{ textAlign: "center" }}         className="mob-icon" >
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
    
              component={Link}
              to={item.path}
              sx={{ textAlign: "center" }}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default MobileNav;
