import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Card, Box, Typography } from "@mui/material";

export default function EventCalender() {
  return (
    <Box sx={{ml:5}} className="event-calender"  >
    <Card className="event-calender-card">
        <Typography component="h2">Daily Activity Calendar</Typography>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar />
    </LocalizationProvider>
    </Card>
    </Box>
  );
}