import React from "react";
import Header from "./header/Header";
import { Container, Typography, Box } from "@mui/material";
import { useTheme } from "../contexts/ThemeContext.js";
import Footer from "./footer/Footer.js";

function InnerLayout({ navItem, theme, toggleTheme }) {
  const { bannertitle, bannerimg, bannercontent } = navItem;

  return (
    <>
      <Header theme={theme} toggleTheme={toggleTheme} />

<Box         className="inner-banner">
<Box
          className="w-100"
          component="img"
          src={bannerimg} // Display the banner image
          alt={bannertitle} // Add alt text for accessibility
          sx={{ width: "100%", height: "auto", mb: 3 }} // Optional styling
        />
</Box>

      <Container>
    
        <Box component="section">
          <Typography variant="h4" component="h1" gutterBottom>
            {bannertitle} {/* Display the banner title */}
          </Typography>
          <Typography
            variant="body1"
            mt={2}
            dangerouslySetInnerHTML={{ __html: bannercontent }} // Render HTML content safely
          />
        </Box>
      </Container>
      <Footer />
    </>
  );
}

export default InnerLayout;
