import godImage from "../../images/god.jpg"; // Import the image at the top of the file

export const heroData = [
  { 
    title: "A PERSPECTIVE ON Hindu Temple, Omaha", 
    tagline: "These Hindu immigrants initially conducted the practices of their faith in their own homes. In the mid 1970's, there was a second wave of immigrants from India to the Lincoln and Omaha area.",
    buttonText: "Read More",
    buttonUrl: "/about-us",  
    image: godImage,  
  },
];
