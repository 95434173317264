import React from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ImgCal from "../../images/cal.jpg";
import EventsList from "./EventsList";
import EventCalender from "./EventCalender";

function UpcomingEvents() {
  return (
    <Box component="section">
      <Box sx={{mx:2}}>
      <Typography
        component="h2"
        variant="h1"
        className="section-title text-primary"
      >
        Upcoming Events
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid item size={{ md: 9 }}>
          <EventsList />
        </Grid>

        <Grid item size={{ md: 3 }}>
          <EventCalender/>
        </Grid>
      </Grid>
      </Box>
    </Box>
  );
}

export default UpcomingEvents;
