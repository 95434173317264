import * as React from "react";

import Img1 from "../../images/01.jpg";
import Img2 from "../../images/02.jpg";
import Img3 from "../../images/03.jpg";
import Img4 from "../../images/04.jpg";
import Img5 from "../../images/05.jpg";
import Img6 from "../../images/06.jpg";



export const navItems = [
  { name: "Articles", path: "/articles", bannertitle: "Articles", bannerimg: Img1, bannercontent: "<h3>A PERSPECTIVE ON Hindu Temple, Omaha</h3> <p>The Hindu temple of Omaha has been serving members of the Hindu faith from the Omaha, Lincoln, and surrounding areas since 1993. The history of Hindu community in Nebraska dates back to the early 1970's when engineers and their families immigrated from India to the Omaha area.</p>  " },
  { name: "About Us", path: "/about-us",  bannertitle: "About Us", bannerimg: Img2, bannercontent: "<h3>A PERSPECTIVE ON Hindu Temple, Omaha</h3> <p>The Hindu temple of Omaha has been serving members of the Hindu faith from the Omaha, Lincoln, and surrounding areas since 1993. The history of Hindu community in Nebraska dates back to the early 1970's when engineers and their families immigrated from India to the Omaha area.</p>  " },
  { name: "Services", path: "/services",  bannertitle: "Services", bannerimg: Img3, bannercontent: "<h3>A PERSPECTIVE ON Hindu Temple, Omaha</h3> <p>The Hindu temple of Omaha has been serving members of the Hindu faith from the Omaha, Lincoln, and surrounding areas since 1993. The history of Hindu community in Nebraska dates back to the early 1970's when engineers and their families immigrated from India to the Omaha area.</p>  " },
  { name: "Support Us", path: "/support-us",  bannertitle: "Support Us", bannerimg: Img4, bannercontent: "<h3>A PERSPECTIVE ON Hindu Temple, Omaha</h3> <p>The Hindu temple of Omaha has been serving members of the Hindu faith from the Omaha, Lincoln, and surrounding areas since 1993. The history of Hindu community in Nebraska dates back to the early 1970's when engineers and their families immigrated from India to the Omaha area.</p>  " },
  { name: "Sant Sadan", path: "/sant-sadan",  bannertitle: "Sant Sadan", bannerimg: Img5, bannercontent: "<h3>A PERSPECTIVE ON Hindu Temple, Omaha</h3> <p>The Hindu temple of Omaha has been serving members of the Hindu faith from the Omaha, Lincoln, and surrounding areas since 1993. The history of Hindu community in Nebraska dates back to the early 1970's when engineers and their families immigrated from India to the Omaha area.</p>  " },
  { name: "Temple Tours", path: "/temple-tours" ,  bannertitle: "Temple Tours", bannerimg: Img6, bannercontent: "<h3>A PERSPECTIVE ON Hindu Temple, Omaha</h3> <p>The Hindu temple of Omaha has been serving members of the Hindu faith from the Omaha, Lincoln, and surrounding areas since 1993. The history of Hindu community in Nebraska dates back to the early 1970's when engineers and their families immigrated from India to the Omaha area.</p>  " },
  { name: "Social Hall Booking", path: "/social-hall-booking",  bannertitle: "Social Hall Booking", bannerimg: Img1, bannercontent: "<h3>A PERSPECTIVE ON Hindu Temple, Omaha</h3> <p>The Hindu temple of Omaha has been serving members of the Hindu faith from the Omaha, Lincoln, and surrounding areas since 1993. The history of Hindu community in Nebraska dates back to the early 1970's when engineers and their families immigrated from India to the Omaha area.</p>  " },
];
