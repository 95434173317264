import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  Link,
  ListItemText,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import F9Logo from "../../images/f9tech.svg";

import "../footer/footer.css";

function Footer() {
  return (
    <Box component="section" className="footer " sx={{ p: 0 }}>
      <Box
        className="footer-inner bg-secondary theme-radius text-white"
        sx={{ py: 5 }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Grid item size={{ md: 4 }} sx={{ pr: 3 }}>
              <Typography component="h5" className="footer-title">
                Quick Links
              </Typography>
              <List className="footer-list">
                <ListItem>
                  <Link className="text-white">
                    {" "}
                    <ArrowRightIcon /> Home
                  </Link>
                </ListItem>

                <ListItem>
                  <Link className="text-white">
                    {" "}
                    <ArrowRightIcon /> About us
                  </Link>
                </ListItem>

                <ListItem>
                  <Link className="text-white">
                    {" "}
                    <ArrowRightIcon /> Privicy & Policy
                  </Link>
                </ListItem>

                <ListItem>
                  <Link className="text-white">
                    {" "}
                    <ArrowRightIcon /> Terms & Conditions
                  </Link>
                </ListItem>

                <ListItem>
                  <Link className="text-white">
                    {" "}
                    <ArrowRightIcon /> Contact Us
                  </Link>
                </ListItem>
              </List>
            </Grid>

            <Grid item size={{ md: 4 }} sx={{ pr: 3 }}>
              <Typography component="h5" className="footer-title">
                Contact Us
              </Typography>

              <List className="footer-list">
                <ListItem>
                  <Link className="text-white">
                    {" "}
                    <FmdGoodIcon /> Hindu Temple Limited, 13010 Arbor Street,
                    Omaha, NE 68144.
                  </Link>
                </ListItem>

                <ListItem>
                  <Link className="text-white">
                    {" "}
                    <LocalPhoneIcon /> (402) 697-8546
                  </Link>
                </ListItem>

                <ListItem>
                  <Link className="text-white">
                    {" "}
                    <EmailIcon /> contact@htom.us
                  </Link>
                </ListItem>
              </List>

              <Box className="social-icons">
                <Link href="#">
                  <FacebookOutlinedIcon />
                </Link>
                <Link href="#">
                  <InstagramIcon />
                </Link>
                <Link href="#">
                  <XIcon />
                </Link>
              </Box>
            </Grid>

            <Grid item size={{ md: 4 }} sx={{ pr: 3 }}>
              <Typography component="h5" className="footer-title">
                Temple Timings
              </Typography>

              <List className="timings" sx={{ width: "100%" }}>
                <ListItem>
                  <ListItemText>Weekdays :</ListItemText>
                  <Typography>
                    09:00AM - 12:30PM and <br />
                    05:30PM - 08:00PM
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemText>Weekends :</ListItemText>
                  <Typography>
                    09:00AM - 01:00PM and <br />
                    05:30PM - 08:00PM
                  </Typography>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    Temple Cafeteria <br />
                    Saturday :
                  </ListItemText>
                  <Typography>
                    09:00AM - 01:00PM and <br />
                    05:30PM - 08:00PM
                  </Typography>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="bg-primary copyrights text-white" sx={{ py: 2 }}>
        <Container maxWidth="xl">
          <Box className="copyinner">
            <Typography>
              ©2024 Hindu Temple Omaha, NE, All rights reserved.
            </Typography>
            <Link href="https://f9tech.com/" target="_blank">
              Designed by:
              <Box component="img" src={F9Logo} />
            </Link>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default Footer;
