import React from "react";
import { Box, Container, Typography, TextField, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import WhatsSacn from "../../images/whats-sacn.jpg";
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';

function JoinOurCommunication() {
  return (
    <Box component="section" className="bg-primary text-white call-to-action">
      <Container maxWidth="xl">
        <Grid
          container className="call-top"
          spacing={2}
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item size={{ md: 4 }} sx={{ px: 3 }}>
            <Typography
              component="h3"
              variant="h2"
              color="text.primary"
              className="text-white"
            >
              Join our Communication to get Notifications
            </Typography>
          </Grid>
          <Grid item size={{ md: 4 }} sx={{ px: 3 }} className="form">
            <Typography>Send Email</Typography>
            <TextField
              id="email-address"
              className="email-address"
              label="Enter your email address"
              variant="outlined"
            />
            <Button variant="contained" color="white" className="text-primary">
              Submit
            </Button>
          </Grid>
          <Grid item size={{ md: 4 }} sx={{ px: 3 }}>
            <Box className="scan">
              <Typography> ( And ) </Typography>
              <Box className="scanDiv">
                <Typography>Whatsapp</Typography>
                <Box component="img" src={WhatsSacn} />
              </Box>
            </Box>
          </Grid>
        </Grid>



          <Box className="face-book-block">
          <Button variant="contained" color="white" href="https://www.facebook.com/HinduTemple.Omaha.Nebraska" target="_blank" className="text-primary">
         <FacebookOutlinedIcon/> Follow us on Facebook
            </Button>
          </Box>




      </Container>
    </Box>
  );
}

export default JoinOurCommunication;
