import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import DateRangeIcon from "@mui/icons-material/DateRange";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { eventData } from "../upcoming-events/event-data.js";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
function EventsList() {
  const settings = {
    dots: eventData.length > 1,
    infinite: eventData.length > 1,
    arrows: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false, 
          autoplay: false,
        },
      },
      {
        breakpoint: 1024, 
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false, 
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {eventData.map((event, index) => (
        <Box key={index} sx={{ px: 3 }} className="event-lists">
          <Card className="theme-radius event-card">
            <CardMedia
              className="theme-radius"
              component="img"
              alt={event.title}
              height="300"
              image={event.image}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="h3"
                dangerouslySetInnerHTML={{ __html: event.title }}
              />
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                <DateRangeIcon /> {event.date}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                <AccessTimeIcon /> {event.time}
              </Typography>
            </CardContent>
            <Link className="link">
              <ArrowRightAltIcon />
            </Link>
          </Card>
        </Box>
      ))}
    </Slider>
  );
}

export default EventsList;
