import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import InnerLayout from "../layouts/InnerLayout.js";
import { navItems } from "../layouts/header/navItems.js";

function Router({ theme, toggleTheme }) {
  return (
    <Routes>
      {/* Static route for Home */}
      <Route path="/" element={<Home />} />

      {/* Dynamic routes for other pages */}
      {navItems
        .filter((item) => item.path !== "/") // Exclude Home
        .map((item) => (
          <Route
            key={item.name}
            path={item.path}
            element={
              <InnerLayout
                navItem={item} // Pass the entire object
                theme={theme}
                toggleTheme={toggleTheme}
              />
            }
          />
        ))}
    </Routes>
  );
}


export default Router;
