import React from "react";
import {
  Box,
  CssBaseline,
  Toolbar,
  IconButton,
  Drawer,
  Button,
  Container,
  List,
  ListItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../images/logo.png";
import MobileNav from "./MobileNav";
import { navItems } from "./navItems.js";
import "./header.css";
const drawerWidth = 240;
export default function Header({ window, theme, toggleTheme }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box>
      <CssBaseline />
      <Box component="header" position="static">
        <Link className="logo" to="/">
          <Box component="img" src={Logo} alt="Logo" />
        </Link>
        <Container maxWidth="xl" className="header-container">
          <Toolbar className="header-inner">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <List className="nav" sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item) => (
                <ListItem key={item.name} disablePadding>
                  <Link
                    to={item.path}
                    className={`nav-link ${
                      location.pathname === item.path ? "active" : ""
                    }`}
                  >
                    {item.name}
                  </Link>
                </ListItem>
              ))}
            </List>
            
          </Toolbar>
        </Container>
      </Box>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        <MobileNav onClose={handleDrawerToggle} />
      </Drawer>
    </Box>
  );
}
